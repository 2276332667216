import type { ThemeConfig } from 'antd'
import localFont from 'next/font/local'

// next/font를 활용하여 Pretendard 폰트를 앱폰트로 지정합니다.
// 다행히(?) and design에서 token으로 폰트를 주입할 수 있었습니다.
// themeConfig.ts는 글로벌로 적용된다고 볼 수 있습니다.
const appFonts = localFont({
  src: '../../public/fonts/PretendardVariable.woff2',
})
export const theme: ThemeConfig = {
  token: {
    fontFamily: appFonts.style.fontFamily,
    // fontSize: 16,
    // colorPrimary: '#52c41a',
  },
  hashed: false,
}
