import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface ModalButton {
  key: string
  name: string
  loading: boolean
  onClick: () => void
  type: 'link' | 'primary' | 'text' | 'default' | 'dashed' | undefined
}

export interface ModalState {
  list: any[]
  params: {
    message?: string
    component?: any
    buttons?: ModalButton[]
    onOk?: () => void
  }
  isModal: boolean
  openModal: (params: any) => void
  closeModal: (keys?: string[] | 'all') => void
  isLoading: boolean
  showLoading: () => void
  hideLoading: () => void
}

export const useModalStore = create<ModalState>()(
  devtools((set, get) => ({
    list: [],
    isLoading: false,
    isModal: false,
    params: {},
    component: null,
    openModal: (params: any) => {
      // const state = get()
      // state.params = params
      // state.isModal = true
      // get('').list.push('wefwe')
      // set({
      //   params,
      //   isModal: true,
      // })
      set((state) => {
        const isNotNewModal = state.list.some(
          (modal) => modal.key && modal.key === params.key,
        )

        return isNotNewModal ? state : { list: [...state.list, params] }
      })
      // set({
      //   params,
      //   isModal: true,
      // })
      // set((state) => {
      //   state.params = params
      //   state.isModal = true
      //   return state
      // })
    },
    closeModal: (keys?: string[] | 'all') => {
      if (keys) {
        if (keys === 'all') {
          set({
            list: [],
          })
          return
        }
        set((state) => ({
          list: state.list.filter((item) => !keys.includes(item.key)),
        }))
        return
      }
      set((state) => ({
        list: state.list.slice(0, -1),
      }))
      // set({
      //   isModal: false,
      //   params: {},
      // })
    },
    showLoading: () => {
      set({
        isLoading: true,
      })
    },
    hideLoading: () => {
      set({
        isLoading: false,
      })
    },
  })),
)
