import { ErrorData } from '@/api/error'

export const nowReserveError: ErrorData = {
  NOT_FOUND_NOW_RESERVE_MENU: {
    errorCode: 'NOT_FOUND_NOW_RESERVE_MENU',
    message:
      '포스에 지금자리선점 메뉴가 존재하지 않습니다.\n포스에서 지금자리선점 메뉴 생성 후 다시 시도해주세요.',
  },
  ALREADY_EXISTS_NOW_RESERVE_MENU: {
    errorCode: 'ALREADY_EXISTS_NOW_RESERVE_MENU',
    message: '테이블링에 이미 지금자리선점 메뉴가 생성되어있습니다.',
  },
}
