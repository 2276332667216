'use client'
import { StyleProvider, createCache, extractStyle } from '@ant-design/cssinjs'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import { useServerInsertedHTML } from 'next/navigation'
import { theme } from '../theme/themeConfig'

import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(isBetween)

const cache = createCache()

// ant design 컴포넌트들을 커스텀하거나 token을 수정할때는 ConfigProvider 를 통해 전달합니다.
const StyledComponentsRegistry = ({
  children,
}: {
  children: React.ReactNode
}) => {
  useServerInsertedHTML(() => (
    <style
      id="antd"
      dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }}
    />
  ))
  return (
    <ConfigProvider theme={theme}>
      <StyleProvider cache={cache}>{children}</StyleProvider>
    </ConfigProvider>
  )
}

export default StyledComponentsRegistry
