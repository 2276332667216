'use client'
import { Error } from '@/api/error'
import { useError } from '@/api/hooks/useError'
import * as Sentry from '@sentry/nextjs'
import {
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryKey,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { setCookie } from 'cookies-next'
import { signOut } from 'next-auth/react'
import { useState } from 'react'
import toast from './toast'

let timer: any
// 로그아웃처리 예외 경로
const exceptionPaths = ['/login', '/signup', '/resetPassword']

const error: Error = {
  status: 401,
  name: '시간초과',
  message: '일정시간 동작이 없어 로그아웃 되었습니다. \n다시 로그인해주세요.',
  errorCode: '401',
}

// 로그인 후 1시간 동안 아무동작없을 경우 로그아웃 처리
const checkAlive = (callback: Function, delay?: number) => {
  clearTimeout(timer)
  timer = setTimeout(
    () => {
      const pathname = window.location.pathname
      const encodedPathname = encodeURIComponent(pathname)
      if (typeof window === 'undefined') return // Next.js 서버사이드 렌더링시 window 객체가 없어서 예외처리
      if (!exceptionPaths.includes(pathname)) {
        setCookie('sessionFinished', 'true')
        callback(encodedPathname)
      }
    },
    1000 * 60 * (delay ?? 60),
  )
}

export default function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { open } = useError()

  const queryErrorHandler = (
    error: Error,
    query: Query<unknown, unknown, unknown, QueryKey>,
  ) => {
    Sentry.captureException(error)

    if (['INVALID_IP'].includes(error.errorCode)) {
      open(error, () =>
        signOut({
          callbackUrl: '/login',
        }),
      )
      return
    }

    if (query.meta?.errorMessage) {
      toast.error(query.meta.errorMessage as string)
    }
    //FIXME: 에러 코드에 따라 에러를 구분해서 일괄 처리할 수 있다
    // else if (error?.message) {
    //   toast.error(error.message)
    // }
  }
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // refetchOnWindowFocus: false, // Disable refetch on window focus
            // retry: false,  // Disable retry on error
          },
          mutations: {
            onMutate: () => {
              checkAlive((encodedPathname: string) => {
                open(
                  error,
                  () =>
                    signOut({ callbackUrl: `/login?from=${encodedPathname}` }),
                  () =>
                    signOut({ callbackUrl: `/login?from${encodedPathname}` }),
                )
              })
            },
          },
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            return queryErrorHandler(error as Error, query)
          },
        }),
      }),
  )

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}
