import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
// import * as jwt from 'jsonwebtoken'

import { UserRole, UserStatus } from '@/types/user.d'

export type Me = {
  createdAt: string
  email: string
  loginType: string
  name: string
  password: string
  passwordSalt: string
  restaurantIdxs?: Array<Number>
  restaurants?: Array<string>
  role: UserRole
  setting: any // {voiceAlarm: {newOrder: true}}
  status: UserStatus
  updatedAt: string
  _id: string
  idx: number
  vanAgentId?: string
}
interface UserState {
  name: string // 추후 삭제 예정
  accessToken: null | string
  refreshToken: null | string
  verifyKey: string
  me: null | Me
  updateName: () => void // 추후 삭제 예정
  updateAccessToken: (accessToken: string) => void
  updateRefreshToken: (refreshToken: string) => void
  updateToken: (params: { accessToken: string; refreshToken: string }) => void
  deleteToken: () => void
  setMe: (data: Me) => void
  updateVerifyKey: (verifyKey: string) => void
}

// persist를 통해 storage에 접근할수 있습니다.
// 그런데 SSR에서 뭐가 잘 안되는지 오류가 발생했고, 별도로 useStore로 감쌀 수 있도록 파일을 하나 더 만들었습니다.
// storage를 선택하거나 하는 부분은 공식문서 예제를 참고하면 됩니다
// https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set, get) => ({
        name: 'ant',
        accessToken: null,
        refreshToken: null,
        verifyKey: '',
        me: null,
        updateName: () => set({ name: 'jin' }),
        updateAccessToken: (accessToken: string) => {
          set({ accessToken })
          // const t: any = jwt.decode(accessToken)
          // cookieAction.setCookie('_at', accessToken, {
          //   expires: t.exp * 1000,
          // })
        },
        updateRefreshToken: (refreshToken: string) => {
          set({ refreshToken })
          // const t: any = jwt.decode(refreshToken)
          // cookieAction.setCookie('_rt', refreshToken, {
          //   expires: t.exp * 1000,
          // })
        },
        updateToken: async (params: {
          accessToken: string
          refreshToken: string
        }) => {
          const { accessToken, refreshToken } = params
          set({
            accessToken,
            refreshToken,
          })
          // const decodeAccessToken: any = jwt.decode(accessToken)
          // const decodeRefreshToken: any = jwt.decode(refreshToken)
          // await cookieAction.setCookie('_at', accessToken, {
          //   expires: decodeAccessToken.exp * 1000,
          // })
          // await cookieAction.setCookie('_rt', refreshToken, {
          //   expires: decodeRefreshToken.exp * 1000,
          // })
        },
        deleteToken: () => set({}),
        setMe: (data) => {
          set({ me: data })
        },
        updateVerifyKey: (verifyKey: string) => {
          set({ verifyKey })
        },
      }),
      {
        name: 'user-storage',
        partialize: (state) => {
          return { token: state.accessToken }
        },
      },
    ),
  ),
)
