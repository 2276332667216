'use client'

import { useModalStore } from '@/lib/useModalStore'
import { Button, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

export default function GlobalModal() {
  const { list, closeModal } = useModalStore(
    useShallow((state) => ({
      list: state.list,
      closeModal: state.closeModal,
    })),
  )

  const [currentModal, setCurrentModal] = useState<any>({})

  useEffect(() => {
    if (list.length > 0) {
      setCurrentModal(list.at(-1))
    } else {
      setCurrentModal({})
    }
  }, [list])

  // if (currentModal.component) {
  //   return currentModal.component
  // }

  const onModalButtonClick = (button: any) => {
    console.log('onModalButtonClick')
    if (button.onClick) {
      button.onClick()
    } else {
      closeModal()
    }
  }

  const renderButtons = () => {
    const buttons = currentModal.buttons || [
      {
        key: 'ok',
        name: '확인',
        type: 'default',
        loading: false,
        onClick:
          currentModal.onOk ||
          (() => {
            closeModal()
          }),
      },
    ]

    return buttons.map((button: any) => {
      return (
        <Button
          key={button.key}
          type={button.type === 'danger' ? 'primary' : button.type || 'default'}
          danger={button.type === 'danger'}
          loading={button.loading || false}
          onClick={() => onModalButtonClick(button)}
          // style={{ margin: '0 8px 4px 0', padding: '5px 16px' }}
        >
          {button.name}
        </Button>
      )
    })
  }

  const defaultModal = () => {
    return list.map((modal: any, index: number) => {
      if (modal.component) {
        return modal.component
      }
      return (
        <Modal
          key={modal.key || 'modal-' + index}
          open={true}
          maskClosable={false}
          onOk={() => {
            closeModal()
          }}
          onCancel={() => {
            if (currentModal.onCancel) {
              currentModal.onCancel()
            }
            closeModal()
          }}
          closeIcon={false}
          width={currentModal.width || 360}
          title={currentModal.title}
          footer={renderButtons()}
        >
          <div
            style={{
              padding: '12px 7px',
              marginBottom: 12,
              whiteSpace: 'pre-wrap',
            }}
          >
            {currentModal.message}
          </div>
        </Modal>
      )
    })
  }

  return defaultModal()
}
