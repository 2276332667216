'use client'
import { useFunctionalModalStore } from "@/lib/useFunctionalModalStore";
import { Modal }  from "antd";
import React from "react";

export default function FunctionalModal () {
  const { modals, closeModal, refresh } = useFunctionalModalStore()

  const handleCancel = (modal: string) => {
    closeModal(modal)
  }

  const afterOpenChange = () => {
    refresh()
  }

  return (
    <>
      {modals.map((modal, idx) => (
        <Modal
          key={`fn-modal-${modal.name}-${idx}`}
          open={modal.isOpen}
          title={modal.title}
          footer={null}
          onCancel={()=>{handleCancel(modal.name)}}
          afterOpenChange={afterOpenChange}
        >
          {modal.content}
        </Modal>
      ))}
    </>
  )
}