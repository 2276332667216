import React from 'react'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface Modal {
  name: string
  isOpen?: boolean
  title?: string
  content?: React.ReactNode
}

export interface ModalState {
  modals: Modal[]
  openModal: (params: Modal) => void
  closeModal: (params: string) => void
  refresh: () => void
}

export const useFunctionalModalStore = create<ModalState>()(
  devtools((set, get) => ({
    modals: [],
    openModal: (params: Modal) =>
      set((state) => ({
        modals: [...state.modals, { ...params, isOpen: true }],
      })),
    closeModal: (name: string) =>
      set((state) => {
        const newModals = state.modals.map((modal) => {
          if (modal.name === name) {
            modal.isOpen = false
          }
          return modal
        })
        return { modals: newModals }
      }),
    refresh: () =>
      set((state) => {
        const newModals = state.modals.filter((modal) => modal.isOpen !== false)
        return { modals: newModals }
      }),
  })),
)
